import { fetchWithToken } from 'api';

export async function getAgents(): Promise<AgentInfo[]> {
  const response = await fetchWithToken('/agents');
  const data = await response.json();

  return (Object.values(data) as any).map(({ agent_id: agentId, ...agent }) => ({
    id: agentId,
    ...agent
  }));
}
