import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { Image } from 'components';
import placeholder from 'assets/images/avatar-placeholder.jpg';
interface MemberCardProps {
  data: AgentInfo;
}

const defaultAvatar = <img src={placeholder} className="img-error" alt="no image" />;

const MemberCard: React.FC<MemberCardProps> = ({ data }) => {
  return (
    <StyledMemberCard className="member-card">
      <Link to={`member?id=${data.id}`} state={data}>
        <div className="member-card__photo-block">
          {data.avatar ? (
            <Image
              src={data.avatar}
              error={defaultAvatar}
              className="member-card__photo"
              alt="member-photo"
            />
          ) : defaultAvatar}
        </div>
      </Link>
      <Link to={`member?id=${data.id}`} state={data} className="member-card__name">
        {data.name}
      </Link>
      <p className="member-card__position">{data.position}</p>
      <a href={`mailto: ${data.email}`} className="member-card__email">
        {data.email}
      </a>
    </StyledMemberCard>
  );
};

const StyledMemberCard = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .member-card {
    &__photo-block {
      width: 100%;
      height: 320px;
      margin: 0 0 16px;
      border-radius: ${props => props.theme.misc.borderRadius};
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__name {
      margin: 0 0 8px;
      font-size: 16px;
      line-height: 24px;
      color: inherit;
      text-decoration: none;
    }
    &__position {
      margin: 0 0 12px;
      font-size: 12px;
      line-height: 16px;
    }
    &__email {
      font-size: 12px;
      line-height: 14px;
      color: ${props => props.theme.colors.primary};
      text-decoration: none;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .member-card {
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    box-shadow: none;
    .member-card {
      &__photo-block {
      }
    }
  }
`;

export default MemberCard;
