import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Button, Modal, ContactForm } from 'components';
import useLayout from 'hooks/useLayout';

import styled from '@emotion/styled';

const TopSection = () => {
  const images = useStaticQuery(graphql`
    {
      cover: file(relativePath: { eq: "cover-our-team.png" }) {
        childImageSharp {
          fluid(maxWidth: 1366, maxHeight: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coverMob: file(relativePath: { eq: "cover-our-team-mob.png" }) {
        childImageSharp {
          fluid(maxWidth: 768, maxHeight: 509) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [openContactModal, setOpenContactModal] = useState(false);
  const layout = useLayout();
  const coverImage =
    layout === 'desktop'
      ? images.cover.childImageSharp.fluid
      : images.coverMob.childImageSharp.fluid;
  return (
    <StyledTopSection className="team-top-section">
      <div className="team-top-section__image-wrapper">
        <Img fluid={coverImage} alt="team-cover-image" className="team-top-section__cover-image" />
      </div>
      <div className="team-info-block">
        <h1 className="team-info-block__title">
          Want to become
          <br />a part of our team?
        </h1>
        <Button
          className="team-info-block__button"
          onClick={() => setOpenContactModal(true)}
          primary>
          I want to join!
        </Button>
      </div>
      <StyledModal
        className="contact-us-modal"
        open={openContactModal}
        onClose={() => setOpenContactModal(false)}>
        <StyledContactForm
          isShowingAttach
          formTitle={`Want to join our team?\n Complete the form below`}
          onSubmit={() => setOpenContactModal(false)}
        />
      </StyledModal>
    </StyledTopSection>
  );
};

const StyledTopSection = styled.div`
  height: 420px;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  .team-top-section {
    &__image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &__cover-image {
      width: 100%;
      height: 100%;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.3;
      }
    }
  }
  .team-info-block {
    position: relative;
    z-index: 2;
    flex: 1;
    max-width: 1334px;
    &__title {
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      color: ${props => props.theme.colors.white};
      margin: 0 0 32px;
      max-width: 465px;
    }
    &__button {
      &.button {
        flex-shrink: 0;
        font-size: 12px;
        line-height: 14px;
        min-width: 109px;
      }
    }
  }

  @media (width: 1366px) {
    .team-info-block {
      margin: 0 0 0 52px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    .team-info-block {
      margin: 0 0 0 22px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 16px 16px 40px;
    height: 509px;

    .team-info-block {
      max-width: 100%;
      margin: 0;
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      &__title {
        text-align: center;
        padding: 0 31px;
        max-width: 100%;
      }
      &__button {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    .team-top-section {
      &__image-wrapper {
        img {
          object-position: -36px center !important;
        }
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  &.contact-us-modal {
    .contact-form {
      margin: 0 10px 12px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    &.contact-us-modal {
      .contact-form {
        margin: 0;
      }
    }
  }
`;
const StyledContactForm = styled(ContactForm)`
  .contact-form__title {
    max-width: 245px;
  }
`;

export default TopSection;
